import React, { useState, useEffect } from 'react';
import './centalmangerlogin.css';
import axios from 'axios';
import Navbar from './navbar';
import { useNavigate } from 'react-router-dom';

const Centralmanagerlogin = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        branchName: ''
    });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [branchNames, setBranchNames] = useState([]);
    const navigate = useNavigate();
    const api = process.env.REACT_APP_API || 'https://dstbackend.ijernd.com';
    // const api = process.env.REACT_APP_API || 'http://localhost:8000';

    useEffect(() => {
        // Fetch branch names from the backend
        axios.get(`${api}/branch-names`)
            .then(response => {
                setBranchNames(response.data);
            })
            .catch(error => {
                console.error('Error fetching branch names:', error);
            });
    }, [api]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${api}/logins`, formData);
            const { branchName } = response.data;
            
            if (branchName) {
                localStorage.setItem('branchName', branchName);
                navigate('/central/CentralManager_Dashboard');
            } else {
                setError('Unknown branch, please contact support.');
            }
        } catch (error) {
            setError('Login failed, please try again.');
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <Navbar />
            <h2 className='centra_manger'>CenterManager Login</h2>
            <form className="login-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Branch</label>
                    <select
                        name="branchName"
                        value={formData.branchName}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select branch</option>
                        {branchNames.map(branch => (
                            <option key={branch} value={branch}>
                                {branch}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Password</label>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="checkbox"
                        id="showPassword"
                        checked={showPassword}
                        onChange={toggleShowPassword}
                    />
                    <label htmlFor="showPassword">Show Password</label>
                </div>
                <button type="submit">Login</button>
                {error && <p className="error-message">{error}</p>}
            </form>
        </>
    );
};

export default Centralmanagerlogin;
