import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './index.css';

const CollegeCount = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [collegeCount, setCollegeCount] = useState({});
  const [filter, setFilter] = useState('');
  const api = process.env.REACT_APP_API || 'https://dstbackend.ijernd.com';

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${api}/users`);

        // Modify the response data if needed
        response.data.shift(1); // Removes the first item from the array

        setUsers(response.data);
        setLoading(false);

        // Calculating college-based count
        const countByCollege = response.data.reduce((acc, user) => {
          const college = user.college_name;
          if (acc[college]) {
            acc[college]++;
          } else {
            acc[college] = 1;
          }
          return acc;
        }, {});
        setCollegeCount(countByCollege);

      } catch (error) {
        console.error('Error fetching users:', error);
        setError('Failed to fetch users.');
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Filter the college names based on the filter input
  const filteredColleges = Object.entries(collegeCount).filter(([college]) =>
    college.toLowerCase().includes(filter.toLowerCase())
  );

  return (
    <div className='containerps'>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
     
      <div>
        <h2>College Counts</h2>
        <input
          type="text"
          placeholder="Filter colleges..."
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
        <ul>
          {filteredColleges.map(([college, count]) => (
            <li key={college}>{college}: {count} users</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CollegeCount;
