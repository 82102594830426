import React, { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import './index.css';

export default function StudentDashboard() {
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const api = process.env.REACT_APP_API || 'https://dstbackend.ijernd.com';
  // const api = process.env.REACT_APP_API || 'http://localhost:8000';

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      axios.get(`${api}/students`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {
        setStudentData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching student data:", error);
        setError("Failed to fetch student data.");
        setLoading(false);
      });
    } else {
      setLoading(false);
      setError("No token found in localStorage.");
    }
  }, []);

  const handleDownload = () => {
    const doc = new jsPDF();
  
    // Title and header
    doc.setFontSize(18);
    doc.text("Datapro Scholarship Test", 105, 20, null, null, "center");
    doc.setFontSize(14);
    doc.text("Datapro Computers Pvt Ltd", 105, 30, null, null, "center");
    doc.setFontSize(16);
    doc.text("Hall Ticket", 105, 40, null, null, "center");
  
    // Adding image logo
    doc.addImage("/DATAPRO.png", "PNG", 10, 10, 40, 30); // Adjust image size and position as needed
  
    // Function to capitalize the first letter of a string
    const capitalize = (str) => {
      if (!str) return "";
      return str.charAt(0).toUpperCase() + str.slice(1);
    };
  
    // Adding student details with alignment
    doc.setFontSize(12);
    
    const startX = 20;  // X position for labels
    const valueX = 90;  // X position for values
    const startY = 60;  // Y start position
  
    doc.text("Hall Ticket Number:", startX, startY);
    doc.text(studentData.hall_ticket_number, valueX, startY);
    
    doc.text("Name:", startX, startY + 10);
    doc.text(`${capitalize(studentData.first_name)} ${capitalize(studentData.last_name)}`, valueX, startY + 10);
    
    doc.text("Gender:", startX, startY + 20);
    doc.text(capitalize(studentData.gender), valueX, startY + 20);
    
    doc.text("Email:", startX, startY + 30);
    doc.text(studentData.email.toLowerCase(), valueX, startY + 30); // Keeping email lowercase
    
    doc.text("Phone Number:", startX, startY + 40);
    doc.text(studentData.phone_number, valueX, startY + 40);
    
    doc.text("College:", startX, startY + 50);
    doc.text(capitalize(studentData.college_name), valueX, startY + 50);
    
    doc.text("Course:", startX, startY + 60);
    doc.text(capitalize(studentData.branch_name), valueX, startY + 60);
    
    doc.text("Exam Center:", startX, startY + 70);
    doc.text(capitalize(studentData.exam_center), valueX, startY + 70);
  
    // Footer for date or other information
    doc.setFontSize(10);
    // doc.text("Generated on: " + new Date().toLocaleDateString(), startX, startY + 80);
  
    // Save the PDF with a meaningful file name
    doc.save(`HallTicket_${studentData.hall_ticket_number}.pdf`);
  };
  if (loading) {
    return <div>Loading student data...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
    <h1 className="wellcome_student">wellcome {studentData.first_name}{studentData.last_name}...</h1>
    <div className="hall-ticket-container">
      <div className="hall-ticket">
        <img src="/DATAPRO.png" alt="Institute Logo" className="institute-logo" />
        <h1>Datapro Computers Pvt Ltd</h1>
        <h2>Hall Ticket</h2>
        <div className="student-info">
          <table>
            <tbody>
              <tr>
                <td><strong>Hall Ticket Number:</strong></td>
                <td>{studentData.hall_ticket_number}</td>
              </tr>
              <tr>
                <td><strong>Name:</strong></td>
                <td>{studentData.first_name} {studentData.last_name}</td>
              </tr>
              <tr>
                <td><strong>Gender:</strong></td>
                <td>{studentData.gender}</td>
              </tr>
              <tr>
                <td><strong>Email:</strong></td>
                <td>{studentData.email}</td>
              </tr>
              <tr>
                <td><strong>Phone Number:</strong></td>
                <td>{studentData.phone_number}</td>
              </tr>
              <tr>
                <td><strong>College:</strong></td>
                <td>{studentData.college_name}</td>
              </tr>
              <tr>
                <td><strong>Course:</strong></td>
                <td>{studentData.branch_name}</td>
              </tr>
              <tr>
                <td><strong>Exam Center:</strong></td>
                <td>{studentData.exam_center}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <button className="download-btn" onClick={handleDownload}>Download Hall Ticket</button>
      </div>
    </div>
    </>
  );
}
