import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import './index.css';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [scoreRange, setScoreRange] = useState('');
  const [approvalDate, setApprovalDate] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [filters, setFilters] = useState({
    hallticketnumber: '',
    score: '',
    branchname: '',
    collegename: '',
    name: ''
  });
  const api = process.env.REACT_APP_API || 'https://dstbackend.ijernd.com';
  // const api = process.env.REACT_APP_API || 'http://localhost:8000';

  // Handle file input change
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle file upload
  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', file);

    axios.post(`${api}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        console.log('File uploaded successfully:', response.data);
        setMessageType('success');
        setMessage('File uploaded successfully.');
        fetchResults();  // Fetch the updated results after the upload
      })
      .catch(error => {
        console.error('File uploaded successfully', error);
        setMessageType('error');
        // setMessage('Error uploading the file.');
      });
  };

  // Handle approving results
  const handleApprove = async () => {
    try {
      if (!approvalDate) {
        setMessageType('error');
        setMessage('Approval Date is required.');
        return;
      }

      const response = await axios.post(`${api}/admin/approve-results`, { approvalDate });
      setMessageType('success');
      setMessage(response.data.message);
    } catch (error) {
      setMessageType('error');
      setMessage('Error approving results.');
    }
  };

  // Fetch results from the server
  const fetchResults = () => {
    axios.get(`${api}/results`)
      .then(response => {
        setResults(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  };

  // Apply filters to the results
  const filterResults = () => {
    let filtered = results;

    if (scoreRange !== '') {
      const [min, max] = scoreRange.split('-').map(Number);
      filtered = filtered.filter(result => result.score >= min && result.score <= max);
    }

    filtered = filtered.filter((result) => {
      const fullName = `${result.first_name || ''} ${result.last_name || ''}`.toLowerCase();
      return (
        (result.hallticketnumber || '').toLowerCase().includes(filters.hallticketnumber.toLowerCase()) &&
        (result.score ? result.score.toString() : '').includes(filters.score) &&
        (result.branchname || '').toLowerCase().includes(filters.branchname.toLowerCase()) &&
        (result.college_name || '').toLowerCase().includes(filters.collegename.toLowerCase()) &&
        fullName.includes(filters.name.toLowerCase())
      );
    });

    setFilteredResults(filtered);
  };

  useEffect(() => {
    fetchResults(); // Fetch the results when the component loads
  }, []);

  useEffect(() => {
    filterResults(); // Filter results whenever scoreRange, filters, or results change
  }, [scoreRange, filters, results]);

  // Handle download of sample Excel file
  const handleDownload = () => {
    const data = [
      ['hallticketnumber', 'score', 'branchname', 'phonenumber', 'date'],
      ['HT102345', '89', 'Dwarakanagara', '6307654123', '30-08-2024'],
      ['HT102345', '89', 'Dwarakanagara', '6307654123', '30-08-2024'],
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'Sample.xlsx');
  };

  // Handle filter input changes
  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
  };

  return (
    <>
      <div className='approvel_result'>
        <h1>Approve Results</h1>
        <input
          type="date"
          value={approvalDate}
          onChange={(e) => setApprovalDate(e.target.value)}
          placeholder="Select Approval Date"
        />
        <button onClick={handleApprove}>Approve Results</button>
        {message && (
          <div style={{ color: messageType === 'success' ? 'green' : 'red' }}>
            {message}
          </div>
        )}
      </div>

      <button
        style={{ marginLeft: "320px", marginTop: "40px", width: "60%" }}
        onClick={handleDownload}
      >
        Download Excel Sheet
      </button>

      <div className='gip'>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload</button>

        <h3>Filter Results</h3>
        <div className='dfghb'>
          <input
            type="text"
            name="hallticketnumber"
            placeholder="Filter by Hall Ticket Number"
            value={filters.hallticketnumber}
            onChange={handleFilterChange}
          />

          <input
            type="text"
            name="branchname"
            placeholder="Filter by Branch Name"
            value={filters.branchname}
            onChange={handleFilterChange}
          />

          <input
            type="text"
            name="collegename"
            placeholder="Filter by College Name"
            value={filters.collegename}
            onChange={handleFilterChange}
          />

          <input
            type="text"
            name="name"
            placeholder="Filter by Name"
            value={filters.name}
            onChange={handleFilterChange}
          />
        </div>
        <div>
          <label htmlFor="scoreRange">Filter by Score Range: </label>
          <select id="scoreRange" onChange={(e) => setScoreRange(e.target.value)}>
            <option value="">All</option>
            <option value="50-60">50-60</option>
            <option value="40-50">40-50</option>
            <option value="30-40">30-40</option>
            <option value="20-30">20-30</option>
            <option value="10-20">10-20</option>
          </select>
        </div>

        <h3>Exam Results</h3>
        <table>
          <thead>
            <tr>
              <th>Hall Ticket Number</th>
              <th>Name</th>
              <th>College Name</th>
              <th>Phone Number</th>
              <th>Score</th>
              <th>Branch Name</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {filteredResults.map((result, index) => (
              <tr key={index}>
                <td>{result.hallticketnumber}</td>
                <td>{result.first_name} {result.last_name}</td>
                <td>{result.college_name}</td>
                <td>{result.phonenumber}</td>
                <td>{result.score}</td>
                <td>{result.branchname}</td>
                <td>{result.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FileUpload;
