import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import './index.css';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [scoreRange, setScoreRange] = useState('');
  const [filters, setFilters] = useState({
    name: '',
    hallTicketNumber: '',
    phoneNumber: ''
  });
  const api = process.env.REACT_APP_API || 'https://dstbackend.ijernd.com';
  // const api = process.env.REACT_APP_API || 'http://localhost:8000';

  // Handle file change for upload
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Handle filter inputs
  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
  };

  // Download sample Excel data
  const handleDownload = () => {
    const data = [
      ['hallticketnumber', 'score', 'branchname', 'phonenumber', 'date'],
      ['HT102345', '89', 'Dwarakngara', '6307654123', '30-08-2024'],
      ['HT102346', '76', 'Dwarakngara', '6307654124', '31-08-2024']
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, 'Sample.xlsx');
  };

  // Upload file to the backend
  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', file);

    axios.post(`${api}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log('File uploaded successfully:', response.data);
      alert('File uploaded successfully');
      fetchResults();
    })
    .catch(error => {
      alert('File uploaded successfully.');
      console.error('There was an error uploading the file!', error);
    });
  };

  // Fetch results from the backend and filter based on branchName from localStorage
  const fetchResults = () => {
    axios.get(`${api}/results`)
      .then(response => {
        console.log('Fetched results:', response.data);
        const branchName = localStorage.getItem('branchName'); // Get branchName from localStorage
        const filteredData = response.data.filter(u => u.branchname === branchName); // Filter by branchName
        setResults(filteredData);
        setFilteredResults(filteredData); // Set filtered data to display
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  };

  // Fetch results on component mount
  useEffect(() => {
    fetchResults();
  }, []);
  // Filter results when score range or filters change
  useEffect(() => {
    filterResults();
  }, [scoreRange, results, filters]);

  // Filter results based on score range, name, hall ticket, and phone number
  const filterResults = () => {
    let filtered = results.filter(Boolean); // Ensure no undefined results

    if (scoreRange !== '') {
      const [min, max] = scoreRange.split('-').map(Number);
      filtered = filtered.filter(result => result.score >= min && result.score <= max);
    }

    filtered = filtered.filter((user) => {
      const fullName = `${user.first_name ?? ''} ${user.last_name ?? ''}`.toLowerCase();
      const hallTicketNumber = user.hallticketnumber ?? '';
      const phoneNumber = user.phonenumber ?? '';

      return (
        fullName.includes(filters.name.toLowerCase()) &&
        hallTicketNumber.toLowerCase().includes(filters.hallTicketNumber.toLowerCase()) &&
        phoneNumber.includes(filters.phoneNumber)
      );
    });

    setFilteredResults(filtered);
  };

  return (
    <>
      <button style={{ marginLeft: "320px", marginTop: "40px", width: "60%" }} onClick={handleDownload}>
        Download Excel Sheet
      </button>
      <div className='gip'>
        <input type="file" onChange={handleFileChange} />
        <button onClick={handleUpload}>Upload</button>
        <div className="filter">
          <input
            type="text"
            name="name"
            placeholder="Filter by Name"
            value={filters.name}
            onChange={handleFilterChange}
          />
          <input
            type="text"
            name="hallTicketNumber"
            placeholder="Filter by Hall Ticket Number"
            value={filters.hallTicketNumber}
            onChange={handleFilterChange}
          />
          <input
            type="text"
            name="phoneNumber"
            placeholder="Filter by Phone Number"
            value={filters.phoneNumber}
            onChange={handleFilterChange}
          />
        </div>

        <div>
          <label htmlFor="scoreRange">Filter by Score Range: </label>
          <select id="scoreRange" onChange={(e) => setScoreRange(e.target.value)}>
            <option value="">All</option>
            <option value="50-60">50-60</option>
            <option value="40-50">40-50</option>
            <option value="30-40">30-40</option>
            <option value="20-30">20-30</option>
            <option value="10-20">10-20</option>
          </select>
        </div>

        <h3>Exam Results</h3>
        <table>
        <thead>
          <tr>
            <th>Hall Ticket Number</th>
            <th>Name</th>
            <th>College Name</th>
            <th>Phone Number</th>
            <th>Score</th>
            <th>Branch Name</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {filteredResults.map((result, index) => (
            <tr key={index}>
              <td>{result.hallticketnumber}</td>
              <td>{result.first_name} {result.last_name}</td>
              <td>{result.college_name}</td>
              <td>{result.phonenumber}</td>
              <td>{result.score}</td>
              <td>{result.branchname}</td>
              <td>{result.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </>
  );
};

export default FileUpload;
