import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './index.css';
import * as XLSX from 'xlsx';

const CentralManagerDashboard = () => {
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    firstName: '',
    mode: '',
    examCenter: '',
     hallTicketNumber: ''
  });
  const [loading, setLoading] = useState(true);
  const [colleges, setColleges] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState('');
  const [studentCount, setStudentCount] = useState(null);
  const [examCenter, setexamCenter] = useState('');
  const [message, setMessage] = useState('');
  const [userCount, setUserCount] = useState(0);
  const [examCenterCount, setExamCenterCount] = useState({});
  const [error, setError] = useState('');
  const api = process.env.REACT_APP_API || 'https://dstbackend.ijernd.com';
  // const api = process.env.REACT_APP_API || 'http://localhost:8000';

  const convertToCSV = (data) => {
    const header = ['First Name', 'Phone Number', 'College Name', 'Branch Name', 'Mode','Hallticketnumber', 'Exam Center'];
    const rows = data.map(user => [
      user.first_name,
      user.phone_number,
      user.college_name,
      user.branch_name,
      user.mode,
      user.hall_ticket_number,
      user.exam_center
    ]);
    return [header, ...rows].map(row => row.join(',')).join('\n');
  };

  const handleDownloadCSV = () => {
    const csvData = convertToCSV(usersToDisplay);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'users_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(usersToDisplay);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users Data');
    XLSX.writeFile(wb, 'users_data.xlsx');
  };

  const handleGenerateExcel = async () => {
    try {
      const response = await axios.get(`${api}/generate-excel/${examCenter}`);
      if (response.status === 200) {
        alert('Excel file generated successfully');
      }
    } catch (error) {
      console.error('Error generating Excel:', error);
      alert('Failed to generate Excel file');
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${api}/users`);
            
            response.data.shift(1); // Removes the first item from the array
            console.log(response.data)
            const filteredData = response.data.filter(u => u.exam_center === localStorage.getItem("branchName"))
            console.log(filteredData)
            setUsers(filteredData);
            setLoading(false);

            // Calculating exam center-based count
            const countByExamCenter = response.data.reduce((acc, user) => {
                const center = user.exam_center;
                if (acc[center]) {
                    acc[center]++;
                } else {
                    acc[center] = 1;
                }
                return acc;
            }, {});
            setExamCenterCount(countByExamCenter);

        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to fetch users.');
            setLoading(false);
        }
    };

    fetchUsers();
}, []);
// useEffect(() => {
//   const fetchColleges = async () => {
//     try {
//       const response = await axios.get(`${api}/colleges`);
//       setColleges(response.data.map(college => college.college_name));
//     } catch (error) {
//       console.error('Error fetching colleges:', error);
//       setError('Failed to fetch colleges.');
//     }
//   };

//   fetchColleges();
// }, [api]);
  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
  };

  const handleDelete = async (userId) => {
    const confirmation = window.confirm("Are you sure you want to delete this user?");
    
    if (confirmation) {
      try {
        await axios.delete(`${api}/users/${userId}`);
        setUsers(users.filter(user => user.id !== userId));
        alert('User deleted successfully');
      } catch (error) {
        console.error('Error deleting user:', error);
        alert('Error deleting user');
      }
    } else {
      alert('User deletion canceled');
    }
  };

  const filteredUsers = users.filter((user) => {
    return (
      user.first_name.toLowerCase().includes(filters.firstName.toLowerCase()) &&
      user.mode.toLowerCase().includes(filters.mode.toLowerCase()) &&
      user.exam_center.toLowerCase().includes(filters.examCenter.toLowerCase()) &&
      user.hall_ticket_number.toLowerCase().includes(filters.hallTicketNumber.toLowerCase()) // Add this line
    );
  });
 
  const usersToDisplay = [...filteredUsers];
   

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="user-table-container">
      <h1 className='dateds'>User Data</h1>
      <div className="user-count-container">
            <h2 style={{color:"white"}}>Total Registered Users: {users.length}</h2>
            </div>
            {/* <div className='user-count-container'>
    <h3 style={{textAlign:"center"}}>Exam Center-Based Count:</h3>
    <div className="exam-center-boxes">
        {Object.entries(examCenterCount).map(([center, count]) => (
            <div key={center} className="exam-center-box">
                {center}: {count}
            </div>
        ))}
    </div>
</div> */}
<div>
     
    </div>
    
      <div className="filter">
        <input
          type="text"
          name="firstName"
          placeholder="Filter by First Name"
          value={filters.firstName}
          onChange={handleFilterChange}
        />
        {/* <input
          type="text"
          name="mode"
          placeholder="Filter by Mode"
          value={filters.mode}
          onChange={handleFilterChange}
        /> */}
         {/* <input
          type="text"
          name="examCenter"
          placeholder="Filter by Exam Center"
          value={filters.examCenter}
          onChange={handleFilterChange}
        /> */}
         <input
  type="text"
  name="hallTicketNumber"
  placeholder="Filter by Hall Ticket Number"
  value={filters.hallTicketNumber}
  onChange={handleFilterChange}
/>
      <button onClick={handleDownloadCSV}>Download CSV</button>
        <br/>
        <button onClick={handleDownloadExcel}>Download Excel</button>
      
        
     
      </div>

      <table className="user-table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Phone Number</th>
            <th>College Name</th>
            <th>BranchName</th>
            <th>Mode</th>
            <th>Hallticketnumber</th>
            <th>Exam Center</th>
          
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {usersToDisplay.map((user) => (
            <tr key={user.id}>
              <td>{user.first_name} {user.last_name}</td>
              <td>{user.phone_number}</td>
              <td>{user.college_name}</td>
              <td>{user.branch_name}</td>
              <td>{user.mode}</td>
              <td>{user.hall_ticket_number}</td>
              <td>{user.exam_center}</td>
              
              

              <td>
                <button onClick={() => handleDelete(user.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CentralManagerDashboard;
