import React, { useState,useEffect } from 'react';
import axios from 'axios';
import './index.css';
import { useNavigate } from 'react-router-dom';

function PasswordReset() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [branchName, setBranchName] = useState('');
  const navigate = useNavigate();
  const api = process.env.REACT_APP_API || 'https://dstbackend.ijernd.com';
  useEffect(() => {
    // Get branchName from localStorage
    const storedBranchName = localStorage.getItem('branchName');
    setBranchName(storedBranchName);

    if (storedBranchName) {
      // Fetch the current password from the backend
      axios.get(`${api}/current-password`, {
        params: { branchName: storedBranchName }
      })
        .then(response => {
          setCurrentPassword(response.data.password);
        })
        .catch(error => {
          console.error('Error fetching current password:', error);
        });
    } else {
      console.error('No branchName found in localStorage');
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!branchName) {
      alert('No branchName found in localStorage');
      return;
    }

    if (newPassword !== confirmPassword) {
      alert('New passwords do not match');
      return;
    }

    // Send the new password to the backend
    axios.post(`${api}/update-password`, {
      branchName,
      newPassword
    })
      .then(response => {
        alert('Password updated successfully');
        navigate("/central/CentralManager_Dashboard")
      })
      .catch(error => {
        console.error('Error updating password:', error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Current Password:</label>
        <input type="password" value={currentPassword} readOnly />
      </div>
      <div>
        <label>New Password:</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Confirm New Password:</label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </div>
      <button type="submit">Update Password</button>
    </form>
  );

}

export default PasswordReset;
