import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './index.css'; // Ensure this file has necessary CSS

Modal.setAppElement('#root'); // This is necessary for accessibility

const StudentResult = () => {
  const [hallTicketNumber, setHallTicketNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [result, setResult] = useState(null);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const api = process.env.REACT_APP_API || 'http://localhost:8000';
  const api = process.env.REACT_APP_API || 'https://dstbackend.ijernd.com';

  const handleFetchResult = async () => {
    // Validate if at least one field is filled
    if (!hallTicketNumber && !phoneNumber) {
      setMessageType('error');
      setMessage('Please enter either Hall Ticket Number or Phone Number.');
      setModalIsOpen(true);
      return;
    }

    // Validate Hall Ticket Number length
    if (hallTicketNumber && hallTicketNumber.length !== 11) {
      setMessageType('error');
      setMessage('Hall Ticket Number must be exactly 11 characters.');
      setModalIsOpen(true);
      return;
    }

    // Validate Phone Number format (should be exactly 10 digits)
    if (phoneNumber && !/^\d{10}$/.test(phoneNumber)) {
      setMessageType('error');
      setMessage('Phone Number must be exactly 10 digits.');
      setModalIsOpen(true);
      return;
    }

    try {
      const response = await axios.post(`${api}/student/result`, { hallTicketNumber, phoneNumber });
      const { result: fetchedResult, message: resultMessage } = response.data;

      if (fetchedResult) {
        setResult(fetchedResult);
        setMessageType('success');
        setMessage('Result successfully.');
      } else if (resultMessage) {
        setMessageType('info');
        setMessage(resultMessage);
      }
    } catch (error) {
      setMessageType('error');
      setMessage('waiting for result.');
    } finally {
      setModalIsOpen(true);
    }
  };

  return (
    <div className='display_try'>
      <h1>Check Your Result</h1>
      <input
        type="text"
        value={hallTicketNumber}
        onChange={(e) => setHallTicketNumber(e.target.value)}
        placeholder="Enter Hall Ticket Number"
        maxLength="11" // Restricts the input length to 11 characters
        pattern="\d*"  // Allows only digits to be entered
      />
      <input
        type="text"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Enter Phone Number"
        maxLength="10" // Restricts the input length to 10 characters
        pattern="\d*"  // Allows only digits to be entered
      />
      <button onClick={handleFetchResult}>Get Result</button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Result Modal"
        style={{
          content: {
            color: messageType === 'success' ? 'green' : messageType === 'info' ? 'blue' : 'red',
            border: `1px solid ${messageType === 'success' ? 'green' : messageType === 'info' ? 'blue' : 'red'}`,
            padding: '20px',
            borderRadius: '10px',
            width: '400px',
            margin: '0 auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <h2>{messageType === 'success' ? 'Success' : messageType === 'info' ? 'Information' : 'Error'}</h2>
        <div>{message}</div>
        <button onClick={() => setModalIsOpen(false)}>Close</button>
      </Modal>

      {result && result.approved && (
        <div>
          <h2>Result Details:</h2>
          <table className="exam_table">
            <thead>
              <tr>
                <th>Hall Ticket Number</th>
                <th>Score</th>
                <th>Branch Name</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{result.hallticketnumber}</td>
                <td>{result.score}</td>
                <td>{result.branchname}</td>
                <td>{result.date}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default StudentResult;
