import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './nav.css'; 

const Navbar = () => {
    const [isMenuActive, setIsMenuActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Manage this based on your auth logic

    const toggleMenu = () => {
        setIsMenuActive(!isMenuActive);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-brand">
                <NavLink to='/' className="navbar-brand">
                    <img src="DATAPRO.png" alt="Logo" className="logo" />
                </NavLink>
            </div>
            <div className={`navbar-menu ${isMenuActive ? 'active' : ''}`}>
                <ul className="navbar-list">
                    <li>
                        <NavLink to="/" activeClassName="active">Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="/awards" activeClassName="active">Scholarship & Awards</NavLink>
                    </li>
                    <li>
                        <NavLink to="/examstructure" activeClassName="active">Exam Structure</NavLink>
                    </li>
                    <li>
                        <NavLink to="/samplequestion" activeClassName="active">Sample Question</NavLink>
                    </li>
                    <div class="dropdown">
  <button class="dropbtn" style={{marginTop:"-2px"}}>Login</button>
  <div class="dropdown-content">
    <a href="/login">Student</a>
    <a href="/login">Admin</a>
    <a href="/centralmangerlogin">CenterManager</a>
  </div>
</div>
                    <li>
                        <NavLink to="/register" activeClassName="active" className="lopds">Register</NavLink>
                    </li>
                
           
</ul>
            </div>
            <div className="navbar-toggle" onClick={toggleMenu}>
                {isMenuActive ? '✖' : '☰'}
            </div>
        </nav>
    );
};

export default Navbar;
