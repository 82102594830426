// src/Central/CentralSidebar/index.js
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import './index.css';
import SetPassword from '../SetPassword'; 
import CentralMangerDashboard from '../CentralMangerDashboard'; 
import FileUpload from '../FileUpload';

const CentralSidebar = () => {
    const [branchName, setBranchName] = useState('');
    const { id } = useParams();
    console.log(id);
    useEffect(() => {
        const storedBranchName = localStorage.getItem('branchName');
        setBranchName(storedBranchName);
    }, []);

    return (
        <div style={{ display: "flex", justifyContent: 'space-between', width: "100vw" }}>
            <div className="sidebar">
                <h2>Central Portal</h2>
                <p style={{color:"white"}}>Welcome to {branchName} Branch</p>
                <nav>
                    <ul>
                        <li>
                            <NavLink to="/central/CentralManager_Dashboard" activeClassName="active-link">Dashboard</NavLink>
                        </li>
                        <li>
                            <NavLink to="/central/Password" activeClassName="active-link">SetPassword</NavLink>
                        </li>
                        <li>
                            <NavLink to="/central/Fileupload" activeClassName="active-link">ResultUpload</NavLink>
                        </li>
                        <li className='click'>
                            <button onClick={() => {
                                localStorage.removeItem('role');
                                window.location.href = '/login';
                            }}>
                                Logout
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            <div style={{ height: "100vh" }}>
                {id === 'CentralManager_Dashboard' && <CentralMangerDashboard />}
                {id === 'Password' && <SetPassword/>}
                 {id === 'Fileupload' && <FileUpload />}
                
            </div>
        </div>
    );
};

export default CentralSidebar;
